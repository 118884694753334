<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <h4 class="title">Расписание врачей</h4>

          <nav class="level">
            <div class="level-left">
              <div class="level-item">
                <p class="control has-addons">
                  <input v-model.trim="query.search" type="text" placeholder="Поиск" class="input" @keyup.enter="search">
                  <a class="button is-info" :class="{ 'is-loading': loading }" @click.prevent="search">Найти</a>
                </p>
              </div>
            </div>
            <div class="level-right">
              <p class="level-item">
                <router-link :to="{ name: 'ScheduleOfDoctor', params: { id: 'new' }}" class="button is-success">Добавить</router-link>
              </p>
            </div>
          </nav>

          <div class="notification is-primary" v-if="query.search && !haveItems()">
            Ничего не найдено
          </div>

          <spinner v-show="loading"></spinner>

          <table class="table" v-if="haveItems() && !loading">
            <thead>
            <tr>
              <th>Врач</th>
              <th>Специальность</th>
              <th>Клиника</th>
              <th>SubjId</th>
              <th>Продолжительность приема</th>
              <th colspan="2"></th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(schedule, index) in items">
              <td>{{ schedule.doctor.full_name }}</td>
              <td>{{ schedule.speciality.name }}</td>
              <td>{{ schedule.clinic.name }}</td>
              <td>{{ schedule.subj_id }}</td>
              <td>{{ schedule.duration }}</td>
              <td class="is-icon">
                <router-link :to="{ name: 'ScheduleOfDoctor', params: { id: schedule.uuid }}">
                  <i class="fa fa-edit"></i>
                </router-link>
              </td>
              <td class="is-icon">
                <a @click.prevent="remove(schedule, index)" :class="{'is-disabled': schedule.removing}">
                  <i class="fa fa-remove"></i>
                </a>
              </td>

            </tr>
            </tbody>
          </table>

          <pagination
                  @change="changePage"
                  :total-pages="pagination.total"
                  :limit="pagination.limit"
                  :page="pagination.current"
          ></pagination>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import scheduleOfDoctorsService from '../../services/scheduleOfDoctors'
import itemListViewMixin from '../../mixins/itemListView'

export default {
  mixins: [itemListViewMixin(scheduleOfDoctorsService, 'schedule_of_doctors', true)],
  methods: {
    remove (schedule, $index) {
      this.removeConfirm(schedule, $index, `Вы действительно хотите удалить расписание врача "${schedule.doctor.full_name}"?`)
    }
  }
}
</script>
