import BaseService from './baseService'

class ScheduleOfDoctorService extends BaseService {
  get url () {
    return 'schedule-of-doctors'
  }

  createModel () {
    return {
      uuid: null,
      doctor: null,
      speciality: null,
      clinic: null,
      duration: 40,
      subj_id: null
    }
  }
}

export default new ScheduleOfDoctorService()

